import { Outcome } from "../analytics/api/Analytics";
import { Stage } from "../analytics/model/CaseConfiguration";
import { Budget } from "./CostModel";
import { WeightedValue, getValue } from "./WeightedValue";

export interface NamedEntity {
  readonly id: string;
  name: string;
}

export interface NamedValue extends NamedEntity {
  values: WeightedValue[];
}

export interface CostApportionment {
  generic: number;
  perParty: Apportionment;
}

export interface Party extends NamedEntity {
  client?: boolean;
  assets?: NamedValue[];
  recoveries?: CostRecovery[];
  apportionment?: CostApportionment;
}

export interface Quantum extends NamedEntity {
  // absolute amount of currency
  value: number;
  // in %, weighted average
  awardedShares: WeightedValue[];
  // in % per defendant
  liabilityShares?: { [defendantId: string]: number };
}

export function getAwardedValue(quantum: Quantum): number {
  return quantum.awardedShares && quantum.awardedShares.length > 0
    ? getValue(quantum.awardedShares).mul(quantum.value).toNumber()
    : quantum.value;
}

export enum IssueRelation {
  And,
  Or,
  Xor,
}

export interface Issue {
  readonly id: string;
  title: string;
  relation?: IssueRelation;
  quantums?: Quantum[];
  defendants?: NamedEntity[];
  // if truthy, winChance applies
  generic?: boolean;
  genericWinChance?: number;
  perDefendantWinChance?: { [defendantId: string]: number };
  subIssues?: Issue[];
}

export interface Claim extends Issue {
  claimant: NamedEntity;
  counterToClaim?: string;
  setOff?: boolean;
  issues?: Issue[];
}

export type Apportionment = { [party: string]: number };

export interface Cost {
  title: string;
  value: number;
  incurred?: boolean;
  apportionment?: Apportionment[];
}

export enum OfferStatus {
  Draft,
  Offered,
  Rejected,
  Accepted,
}

export interface SettlementOffer {
  id: string;
  // party proposing offer (could be receiving or paying)
  offerorId: string;
  // party receiving offer (not necessarily the money, could be payer)
  offereeId: string;
  payment: { amount: number; offereePays: boolean };
  stage: Stage;
  status: OfferStatus;
  costs: {
    settlement: {
      offeror: number;
      offeree: number;
      apportioned?: number;
    };
    // receiving party can apportion the settlement sum towards money they spent fighting payer
    towardsPayer: {
      // amount apportioned
      apportioned?: number;
    };
    // receiving party can apportion the settlement sum towards their other costs of the case
    other: {
      // apportioned
      apportioned?: number;
    };
  };
  settledClaims: string[];
  settledQuantums: {
    id: string;
    apportioned?: number;
  }[];
}

export interface CostsOfParty {
  party: string;
  costs: Cost[];
}

export enum RecoveryType {
  CourtFees,
  LawyerCosts,
}

export interface CostRecovery {
  readonly id: string;
  type: RecoveryType;
  percentage: number;
}

export interface Evs {
  own: ValueOfStage[];
  vsOthers: { [partyId: string]: ValueOfStage[] };
}

export interface ValueOfStage {
  stage: Stage;
  value: number;
}

export interface PartyDescriptor {
  name: string;
  isClient?: boolean;
  assetLimit?: number;
  opponents: string[];
}

export interface PartyDescriptors {
  [partyId: string]: PartyDescriptor;
}

export interface Opponents {
  [partyId: string]: { [opponentId: string]: string[] };
}

// summary of settlement offer impact on case
export interface SettlementImpact {
  offerId: string;
  offeror: OfferImpact;
  offeree: OfferImpact;
}

export interface OfferImpact {
  // EV values at stage of offer if the offer is accepted
  evAtStageOfOffer: {
    readonly ofParty: number;
    readonly mid: number;
    readonly ofOpponent: number;
  };
  netResult: { baseline: number; settled: number };
}

export type CaseStats = {
  quantumsClaimed: {
    // how many?
    amount: number;
    // total monetary value
    totalValue: number;
  };
  quantumsClaimedAgainst: CaseStats["quantumsClaimed"];
  probabilityOfNegativeJudgement: number;
  probabilityOfNegativeNetResult: number;
  netResult: {
    best: number;
    average: number;
    worst: number;
  };
  ev: number;
  settlements: {
    accepted: number;
    settledClaims: number;
    settledOpponents: number;
    // how much has the party received or will receive through settlements
    paymentsToParty: number;
    // how much has the party paid or will pay to other parties in settlements
    paymentsByParty: number;
  };
  costs: {
    disbursements: {
      incurred: number;
      estimated: number;
    };
    lawFirmFees: CaseStats["costs"]["disbursements"];
  };
};
export type PartyStats = {
  partyId: string;
  stats: CaseStats;
};

export interface Results {
  partyDescriptors: PartyDescriptors;
  opponents: Opponents;
  evs: { [partyId: string]: Evs };
  outcomes: Outcome[];
  settlementData: SettlementDataOfParty[];
  settlementImpacts?: SettlementImpact[];
  bargainingData?: BargainingData[];
  partyStats?: PartyStats[];
}

// configuration allows to modify main case parameters and apply them (e.g. to simulate bargaining)
export interface Configuration {
  id: string;
  timestamp: number;
  winChances: {
    [issueId: string]: {
      generic: boolean;
      genericWinChance: number;
      perDefendantWinChance: {
        [defendantId: string]: number;
      };
    };
  };
  assets: { [defendantId: string]: number | null };
  costs: { [defendantId: string]: number | undefined };
}

export type WinChanceMutation = Variants["sensitivity"]["winChance"][0];

export interface Variants {
  bargaining?: Configuration;
  sensitivity: {
    winChance: {
      // which issue/claim
      issueId: string;
      issueLabel: string;
      // how much change
      delta: number;
      // win chance v. which defendant or, if not set, generic
      defendantId?: string;
      // results for -delta
      minusVariantId?: string;
      // results for +delta
      plusVariantId?: string;
    }[];
  };
}

export interface Case extends NamedEntity {
  createdAt?: string;
  updatedAt?: string;
  parties?: Party[];
  claims?: Claim[];
  budgets?: Budget[];
  settlements?: SettlementOffer[];
  results?: Results;
  variants?: Variants;
  variantResults?: { [variantId: string]: Results };
}

// settlement types
export interface SettlementRange {
  readonly partyEv: number;
  readonly mid: number;
  readonly opponentEv: number;
}
export interface SettlementRangeOfStage {
  stage: Stage;
  range: SettlementRange;
  settlement?: {
    amount: number;
    effect: SettlementRange;
  };
}
export interface SettlementRanges {
  partyId: string;
  opponentId: string;
  perStage: SettlementRangeOfStage[];
}
export interface SettlementData extends SettlementRanges {
  readonly bestEnforcement?: number;
  readonly worstEnforcement?: number;
  readonly netResult: number;
}

export interface SettlementDataOfParty {
  readonly partyId: string;
  readonly data: SettlementData[];
}

export interface BargainingData {
  partyId: string;
  opponents: {
    [opponentId: string]: {
      baseline: SettlementRanges;
      opponentRanges: SettlementRanges;
    };
  };
}
