import Parse from "parse";
import { User } from "./User";
const sessionTokenKey = "sessionToken";

Parse.serverURL = "https://legaltechvault.com/parse";
Parse.initialize("casesolv");

// Parse.serverURL = "http://localhost:1337/parse";
// Parse.initialize("casesolv");

const feedToken = (consumer: (token: string) => void) => {
  const localStorageToken = localStorage.getItem(sessionTokenKey);
  if (localStorageToken) {
    console.debug("Got session token from localStorage");
    consumer(localStorageToken);
    return;
  }
  const sessionStorageToken = sessionStorage.getItem(sessionTokenKey);
  if (sessionStorageToken) {
    console.debug("Got session token from sessionStorage");
    consumer(sessionStorageToken);
    return;
  }
  console.log("No session token found");
};

function saveInSessionStorage(token: string): void {
  sessionStorage.setItem(sessionTokenKey, token);
  localStorage.removeItem(sessionTokenKey);
}

function saveInLocalStorage(token: string): void {
  localStorage.setItem(sessionTokenKey, token);
  sessionStorage.removeItem(sessionTokenKey);
}

function deleteToken() {
  localStorage.removeItem(sessionTokenKey);
  sessionStorage.removeItem(sessionTokenKey);
}

class ParseAuth {
  private onAuthenticated: ((user: User) => void) | undefined = undefined;

  setCallback(newOnAuthenticated: (user: User) => void): () => void {
    this.onAuthenticated = newOnAuthenticated;
    return () => {
      if (this.onAuthenticated === newOnAuthenticated)
        this.onAuthenticated = undefined;
    };
  }

  async checkSession() {
    const currentUser = Parse.User.current();
    if (currentUser !== null && currentUser !== undefined) {
      // already authenticated, nothing to do
      console.log("Already authenticated");
      if (this.onAuthenticated !== undefined) {
        this.onAuthenticated({
          username: currentUser.getUsername() || "Unknown",
          lastName: currentUser.get("lastName"),
          firstName: currentUser.get("firstName"),
          canViewDecisionTree: currentUser.get("canViewDecisionTree"),
        });
      }
      return;
    }
    await feedToken((token) =>
      Parse.User.become(token)
        .then((user) => {
          console.log("Logged in to existing session");
          if (this.onAuthenticated !== undefined) {
            this.onAuthenticated({
              username: user.getUsername() || "Unknown",
              lastName: user.get("lastName"),
              firstName: user.get("firstName"),
              canViewDecisionTree: user.get("canViewDecisionTree"),
            });
          }
        })
        .catch((error) => {
          console.log("Failed to log in using old session", error);
          deleteToken();
        })
    );
  }

  async login(
    username: string,
    password: string,
    remember = false
  ): Promise<void> {
    if (this.isAuthenticated()) {
      // already authenticated, nothing to do
      return;
    }
    console.log("Logging in");
    const loggedInUser: Parse.User = await Parse.User.logIn(username, password);

    console.log("Signed in user " + loggedInUser.get("lastName"));
    if (remember) {
      saveInLocalStorage(loggedInUser.getSessionToken());
    } else {
      saveInSessionStorage(loggedInUser.getSessionToken());
    }
    if (this.onAuthenticated !== undefined) {
      this.onAuthenticated({
        username: username,
        lastName: loggedInUser.get("lastName"),
        firstName: loggedInUser.get("firstName"),
        canViewDecisionTree: loggedInUser.get("canViewDecisionTree"),
      });
    }
  }

  async logout(): Promise<void> {
    if (!this.isAuthenticated()) {
      // already signed out, nothing to do
      return;
    }
    // log out
    deleteToken();
    console.log("Logging out");
    await Parse.User.logOut()
      .then(() => console.log("Logged out"))
      .catch((error) => console.log("Failed to log out", error));
  }

  isAuthenticated() {
    return Parse.User.current() !== null;
  }

  getToken(): string | undefined {
    return Parse.User.current()?.getSessionToken();
  }
}

const auth = new ParseAuth();

export default auth;
