import { Alert, Box, Typography, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Big from "big.js";
import { ChartData, Point } from "chart.js";
import "chart.js/auto";
import { t } from "i18next";
import { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { Trans } from "react-i18next";
import { WeighedAverage } from "../../analytics/Value";
import { Judgement, Outcome } from "../../analytics/api/Analytics";
import {
  lessValueFirst,
  worseEnforcementBalanceFirst,
} from "../../analytics/api/JudgmentCompareFns";
import { Stage } from "../../analytics/model/CaseConfiguration";
import { Currency, gbp } from "../../i18n/Currency";
import { PartyDescriptors } from "../../model/CaseModel";
import combineCompareFns from "../../utils/combineCompareFns";
import { TwoLiner } from "../general/TwoLiner";
import { percentage } from "../issues/Claims";
import { getMaxAbsoluteValue } from "./ChartsUtility";

const compareFn = combineCompareFns<Judgement>([
  worseEnforcementBalanceFirst,
  lessValueFirst,
]);

export const varianceFormatter = new Intl.NumberFormat("en-GB", {
  minimumFractionDigits: 1,
  maximumFractionDigits: 3,
});

export const OutcomeRiskAnalysis = ({
  outcome,
  currency = gbp,
  parties = {},
}: {
  outcome: Outcome;
  currency?: Currency;
  parties?: PartyDescriptors;
}) => {
  const theme = useTheme();
  const party = parties[outcome.partyId];

  const sortedJudgments = useMemo(
    () => [...outcome.judgments].sort(compareFn),
    [outcome]
  );

  if (sortedJudgments.length === 0)
    return (
      <Typography key="stage-label">
        <Trans
          i18nKey="case.analytics.risk.description"
          values={{ count: 0 }}
        />
      </Typography>
    );

  const labels: string[] = [];
  const judgmentEvs: Point[] = [];
  const netResults: Point[] = [];
  const evAvg = new WeighedAverage();
  let probability = 0;
  let negativeOutcomes = 0;
  let negativeOutcomesProbability = 0;
  let assetLimitReached = 0;
  let assetLimitReachedProbability = 0;
  let bestEv: number | undefined;
  let worstEv: number | undefined;
  const evs: { value: Big; weight: Big }[] = [];

  const trialStage = outcome.stageResults[Stage.Trial];
  const netResultWithTrial = trialStage
    ? trialStage.netResult - trialStage.estimated.total
    : 0;

  sortedJudgments.forEach((judgment) => {
    const worstEnforcement =
      judgment.enforcementVariants.length > 0
        ? judgment.enforcementVariants[0]
        : undefined;

    const enforcementBalance = worstEnforcement ? worstEnforcement.balance : 0;
    if (worstEnforcement === undefined) {
      console.log("No enforcement in judgement, assuming value of 0", judgment);
    }
    evs.push({
      value: new Big(enforcementBalance),
      weight: new Big(judgment.probability),
    });

    if (bestEv) bestEv = Math.max(enforcementBalance, bestEv);
    else bestEv = enforcementBalance;
    if (worstEv) worstEv = Math.min(enforcementBalance, worstEv);
    else worstEv = enforcementBalance;

    labels.push(percentage.format(probability));

    // calculate averages
    evAvg.add({
      value: enforcementBalance,
      probability: judgment.probability,
    });

    judgmentEvs.push({
      x: probability,
      y: enforcementBalance,
    });
    netResults.push({
      x: probability,
      y: enforcementBalance + netResultWithTrial,
    });
    probability += judgment.probability;
    if (enforcementBalance < 0) {
      negativeOutcomes++;
      negativeOutcomesProbability += judgment.probability;
    }
    if (
      party.assetLimit !== undefined &&
      party.assetLimit <= -enforcementBalance
    ) {
      assetLimitReached++;
      assetLimitReachedProbability += judgment.probability;
    }
  });

  // add 100% to end of X axis
  labels.push(percentage.format(1));
  if (judgmentEvs.length > 0) {
    judgmentEvs.push({
      x: 1,
      y: judgmentEvs[judgmentEvs.length - 1].y,
    });
    netResults.push({
      x: 1,
      y: netResults[netResults.length - 1].y,
    });
  }

  const chartData: ChartData<"line", (number | Point)[]> = {
    labels,
    datasets: [
      {
        label: "Enforcement result",
        data: judgmentEvs,
        stepped: true,
        fill: true,
        order: 2,
        backgroundColor: theme.palette.secondary.main + "75",
        borderColor: theme.palette.secondary.dark,
        pointHitRadius: 20,
      },
      {
        label: "Net result",
        data: netResults,
        stepped: true,
        fill: false,
        order: 1,
        backgroundColor: theme.palette.warning.light,
        borderColor: theme.palette.warning.dark,
        borderWidth: 2,
        borderDash: [6, 6],
        pointHitRadius: 20,
      },
      {
        label: "Value of the case",
        data: [{ x: 0.5, y: evAvg.get()?.toNumber() || 0 }],
        order: 1,
        pointStyle: "circle",
        pointRadius: 6,
        pointHoverRadius: 10,
        pointHitRadius: 20,
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.dark,
      },
    ],
  };
  let maxChartValue = getMaxAbsoluteValue(chartData);
  // if there is an asset value and it is less than double the highest value, show it
  if (party?.assetLimit !== undefined && party.assetLimit < maxChartValue * 2) {
    chartData.datasets.push({
      label: "Asset limit",
      data: [
        { x: 0, y: -party.assetLimit },
        { x: 1, y: -party.assetLimit },
      ],
      order: 0,
      backgroundColor: theme.palette.error.dark,
      borderColor: theme.palette.error.light,
      borderWidth: 2,
    });
    // make sure to scale in case asset value is higher
    maxChartValue = getMaxAbsoluteValue(chartData);
  }

  return (
    <Box>
      <Typography key="stage-label">
        <Trans
          i18nKey="case.analytics.risk.description"
          values={{
            count: sortedJudgments.length,
            name: parties[outcome.partyId].name,
          }}
        />
      </Typography>

      <Stack
        direction="row"
        spacing={2}
        key={"judgments_summary"}
        mt={2}
        mb={2}
      >
        <TwoLiner
          key="judgments_total"
          primary={t("case.analytics.risk.summary.total")}
          secondary={sortedJudgments.length + ""}
        />
        <TwoLiner
          key="judgments_negative"
          primary={t("case.analytics.risk.summary.negative")}
          secondary={negativeOutcomes + ""}
        />
        <TwoLiner
          key="judgments_negative_probability"
          primary={t("case.analytics.risk.summary.negativeProbability")}
          secondary={percentage.format(negativeOutcomesProbability)}
        />
        <TwoLiner
          key="judgment_worst"
          primary={t("case.analytics.risk.summary.worst")}
          secondary={worstEv === undefined ? "--" : currency.format(worstEv)}
        />
        <TwoLiner
          key="judgment_best"
          primary={t("case.analytics.risk.summary.best")}
          secondary={bestEv === undefined ? "--" : currency.format(bestEv)}
        />
      </Stack>
      {assetLimitReached > 0 && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          <Trans
            i18nKey="case.analytics.risk.warning.assetLimit"
            values={{
              name: parties[outcome.partyId].name,
              count: assetLimitReached,
              probability: percentage.format(assetLimitReachedProbability),
            }}
          />
        </Alert>
      )}

      <Line
        data={chartData}
        id={"judgments"}
        options={{
          plugins: {
            legend: {
              labels: {
                font: {
                  family: theme.typography.fontFamily,
                },
              },
            },
            tooltip: {
              mode: "index",
              intersect: true,
              bodyFont: {
                family: theme.typography.fontFamily,
              },
              callbacks: {
                title: () => "",
                label: (item) => {
                  if (item.dataset.label !== "EV per judgment")
                    return (
                      item.dataset.label + ": " + currency.format(item.parsed.y)
                    );
                  const probability =
                    item.dataIndex === sortedJudgments.length
                      ? sortedJudgments[item.dataIndex - 1].probability
                      : sortedJudgments[item.dataIndex].probability;
                  return (
                    percentage.format(probability) +
                    ": " +
                    currency.format(item.parsed.y)
                  );
                },
              },
            },
          },
          scales: {
            x: {
              type: "linear",
              ticks: {
                font: {
                  family: theme.typography.fontFamily,
                },
                callback: function (value) {
                  if (typeof value === "number")
                    return percentage.format(value);
                  return value;
                },
              },
            },
            y: {
              min: -1.1 * maxChartValue,
              max: 1.1 * maxChartValue,
              ticks: {
                font: {
                  family: theme.typography.fontFamily,
                },
                callback: function (value) {
                  if (typeof value === "number") return currency.format(value);
                  return value;
                },
              },
            },
          },
        }}
      />
    </Box>
  );
};
