import { Alert, Box, Divider, Slider, Typography } from "@mui/material";
import { t } from "i18next";
import { useMemo, useState } from "react";
import { Trans } from "react-i18next";
import {
  Apportionment,
  CostApportionment,
  PartyDescriptors,
} from "../../model/CaseModel";
import { ApportionmentBarChart } from "../analytics/ApportionmentChart";
import { PartyValue } from "../analytics/PerPartyBarChart";
import { TwoLiner } from "../general/TwoLiner";
import { percentage } from "../issues/Claims";
import { CostApportionmentOfParty } from "./CostApportionments";

const CostApportionmentSlider = ({
  label,
  value,
  max = 1,
  onSliding,
  onUpdate,
  disabled = false,
}: {
  label: string;
  value: number;
  max?: number;
  onSliding: (newPercentage: number) => void;
  onUpdate: (newPercentage: number) => void;
  disabled?: boolean;
}) => {
  const [sliderValue, setSliderValue] = useState(value * 100);
  const maxSliderValue = max * 100;
  return (
    <Box mt={2} mb={2}>
      <Typography variant="overline">{label}</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          userSelect: "none",
        }}
      >
        <TwoLiner
          primary={percentage.format(sliderValue / 100)}
          secondary={t("case.apportionments.details.percentage")}
        />
        <Slider
          disabled={disabled}
          sx={{
            //  make sure value label does not stick out too much
            mt: 2,
            ml: 2,
            minWidth: 150,
            maxWidth: 200,
          }}
          color="primary"
          size="small"
          value={sliderValue}
          aria-label="Small"
          valueLabelFormat={(value) => percentage.format(value / 100)}
          valueLabelDisplay="auto"
          onChangeCommitted={(ignored, value) => {
            if (typeof value !== "number") return;
            const normalized = Math.min(value, maxSliderValue);
            setSliderValue(normalized);
            onUpdate(normalized / 100);
          }}
          onChange={(ignored, value) => {
            if (typeof value !== "number") return;
            const normalized = Math.min(value, maxSliderValue);
            if (normalized === sliderValue) return;
            setSliderValue(normalized);
            onSliding(normalized / 100);
          }}
          marks={max < 1 ? [{ value: max * 100 }] : []}
        />
      </Box>
    </Box>
  );
};

function getTotal(apportionment: Apportionment | undefined): number {
  if (apportionment === undefined) return 0;
  let perPartyTotal = 0;
  Object.values(apportionment || {}).forEach(
    (value) => (perPartyTotal += value)
  );
  return perPartyTotal;
}

export const CostApportionmentsOfParty = ({
  apportionment,
  update,
  opponents,
  parties,
}: {
  apportionment: CostApportionmentOfParty;
  update(newValue: CostApportionment): Promise<void>;
  opponents: string[];
  parties: PartyDescriptors;
}) => {
  const [error, setError] = useState<string>();
  const [saving, setSaving] = useState(false);

  const perPartyTotal = getTotal(apportionment.apportionment?.perParty);
  const generic = perPartyTotal >= 1 ? 0 : 1 - perPartyTotal;
  const [genericDelta, setGenericDelta] = useState(0);

  const save = (partyId: string, percentage: number) => {
    setSaving(true);
    const perParty: Apportionment = {
      ...apportionment.apportionment?.perParty,
    };
    perParty[partyId] = percentage;

    update({ generic: 1 - getTotal(perParty), perParty })
      .then(() => {
        setSaving(false);
        setError(undefined);
        setGenericDelta(0);
        console.debug(
          "Updated apportionment of party " + apportionment.partyId
        );
      })
      .catch((error) => {
        setSaving(false);
        console.warn(
          "Failed to update apportionment of party " + apportionment.partyId,
          error
        );
        setError(error);
      });
  };

  const genericCosts = Math.abs(generic + genericDelta);
  const genericCostPerParty =
    genericCosts / (opponents.length === 0 ? 1 : opponents.length);

  const apportionmentChartData: PartyValue[] = useMemo(() => {
    return opponents.map((opponentId) => {
      const party = parties[opponentId];
      const value = apportionment.apportionment?.perParty[opponentId] ?? 0;
      return {
        partyId: opponentId,
        value,
        partyName: party.name ?? opponentId,
        client: party?.isClient ?? false,
      };
    });
  }, [apportionment]);

  const recoveriesChartData: PartyValue[] = useMemo(() => {
    return opponents.map((opponentId) => {
      const party = parties[opponentId];
      const value = apportionment.apportionment?.perParty[opponentId] ?? 0;
      return {
        partyId: opponentId,
        value,
        partyName: party.name ?? opponentId,
        client: party?.isClient ?? false,
      };
    });
  }, [apportionment]);

  return (
    <Box sx={{ userSelect: "none" }}>
      {error && (
        <Alert severity="error">
          <Trans
            i18nKey={"case.apportionments.error.updateFailed"}
            values={{ error }}
          />
        </Alert>
      )}
      <Typography mb={1}>
        <Trans
          i18nKey={"case.apportionments.party.description"}
          values={{ count: opponents.length, name: apportionment.partyName }}
        />
      </Typography>
      <TwoLiner
        primary={percentage.format(Math.abs(generic + genericDelta))}
        secondary={t("case.apportionments.generic")}
      />

      {opponents.map((opponentId, index) => {
        const value = apportionment.apportionment?.perParty[opponentId] || 0;
        const max = Math.min(1, value + generic);
        return (
          <CostApportionmentSlider
            key={"apportionment_slider_" + index}
            label={t("case.apportionments.perParty", {
              name: parties[opponentId]?.name || opponentId,
            })}
            value={value}
            max={max}
            onUpdate={(newValue) => {
              if (newValue === value) return;
              save(opponentId, newValue);
            }}
            onSliding={(sliderValue) => {
              const genericDelta = value - sliderValue;
              setGenericDelta(genericDelta);
            }}
            disabled={saving}
          />
        );
      })}

      <Divider />
      <Box mt={4} sx={{ userSelect: "none" }} maxWidth="75%">
        <Typography mb={1}>
          <Trans
            i18nKey={"case.apportionments.results"}
            values={{ name: apportionment.partyName }}
          />
        </Typography>
        <ApportionmentBarChart
          data={apportionmentChartData}
          onSelected={() => {
            // ignore
          }}
          formatValue={percentage.format}
          genericCosts={genericCostPerParty}
        />
      </Box>
      <Divider />
      <Box mt={4} sx={{ userSelect: "none" }} maxWidth="75%">
        <Typography mb={1}>
          <Trans
            i18nKey={"case.apportionments.recoveries"}
            values={{ name: apportionment.partyName }}
          />
        </Typography>
        <ApportionmentBarChart
          data={recoveriesChartData}
          onSelected={() => {
            // ignore
          }}
          formatValue={percentage.format}
          genericCosts={genericCosts}
        />
      </Box>
    </Box>
  );
};
