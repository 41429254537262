import React, { useContext, useMemo } from "react";
import { useLoaderData } from "react-router";
import { CostSummary } from "../analytics/CostUtility";
import { Outcome } from "../analytics/api/Analytics";
import { LocalAnalytics } from "../analytics/api/LocalAnalytics";
import { getStages } from "../analytics/model/British";
import { Stage } from "../analytics/model/CaseConfiguration";
import { useCaseApi } from "../api/case/parse/ParseCaseClient";
import { IssueDescriptors } from "../components/analytics/AnalyticsProps";
import { ClaimMatrix } from "../components/analytics/ClaimMatrix";
import { EvMatrix } from "../components/analytics/EvMatrix";
import {
  BargainingData,
  Case,
  PartyDescriptors,
  PartyStats,
  Results,
  SettlementDataOfParty,
  SettlementImpact,
} from "../model/CaseModel";
import { SubGraph } from "../tree/tree";

export interface CaseResults {
  getPartyDescriptors(): PartyDescriptors;
  getOutcomes(): Outcome[];
  getEvMatrix(): EvMatrix;
  getSettlementData(): SettlementDataOfParty[];
  getBargainingData(): BargainingData[];
}

export interface CaseContextProps extends CaseResults {
  getCase(): Case;
  setCaseTitle(newTitle: string): Promise<void>;
  getStages(): Stage[];
  getIssueDescriptors(): IssueDescriptors;
  getClaimMatrix(): ClaimMatrix;
  getCostSummaryOf(partyId: string): CostSummary;
  getDecisionTrees(): SubGraph | undefined;
  getVariantResults(variantId: string): CaseResults | undefined;
  getOfferImpact(offerId: string): SettlementImpact | undefined;
  getPartyStats(partyId: string): PartyStats | undefined;
}

export const CaseContext = React.createContext<CaseContextProps>({
  getCase: function () {
    throw new Error("Case context not initialized");
  },
  setCaseTitle: function () {
    throw new Error("Case context not initialized");
  },
  getOutcomes: function () {
    throw new Error("Case context not initialized");
  },
  getEvMatrix: function () {
    throw new Error("Case context not initialized");
  },
  getClaimMatrix: function () {
    throw new Error("Case context not initialized");
  },
  getPartyDescriptors: function () {
    throw new Error("Case context not initialized");
  },
  getIssueDescriptors: function () {
    throw new Error("Case context not initialized");
  },
  getDecisionTrees: function () {
    throw new Error("Case context not initialized");
  },
  getStages: function () {
    throw new Error("Case context not initialized");
  },
  getCostSummaryOf: function () {
    throw new Error("Case context not initialized");
  },
  getSettlementData: function () {
    throw new Error("Case context not initialized");
  },
  getBargainingData: function () {
    throw new Error("Case context not initialized");
  },
  getVariantResults: function () {
    throw new Error("Case context not initialized");
  },
  getOfferImpact: function () {
    throw new Error("Case context not initialized");
  },
  getPartyStats: function () {
    throw new Error("Case context not initialized");
  },
});

// provides the case context based on case loaded by react-router's loader
export const CaseContextProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { c } = useLoaderData() as { c: Case };
  const analytics = useMemo(() => new LocalAnalytics(c, getStages()), [c]);

  const decisionTree = useMemo(() => analytics.getDecisionTrees(), [c]);

  const issueDescriptors: IssueDescriptors = {};
  c.claims?.forEach((claim) => {
    issueDescriptors[claim.id] = {
      title: claim.title,
      claimant: claim.claimant.name,
    };
    claim.issues?.forEach((issue) => {
      issueDescriptors[issue.id] = {
        title: issue.title,
        claimant: claim.claimant.name,
      };
    });
  });

  const claimMatrix = useMemo(
    () => new ClaimMatrix(c.results?.opponents ?? {}),
    [c]
  );

  const api = useCaseApi();
  const value: CaseContextProps = {
    ...asCaseResults(c.results),
    getCase: () => c,
    setCaseTitle: (newTitle) => api.setTitle(c.id, newTitle),
    getIssueDescriptors: () => issueDescriptors,
    getStages,
    // results
    getClaimMatrix: () => claimMatrix,
    getCostSummaryOf: (partyId) => analytics.getCostSummary(partyId),
    // debugging
    getDecisionTrees: () => decisionTree,
    getVariantResults: (variantId: string) => {
      if (!c.variantResults) return;
      const variantResults = c.variantResults[variantId];
      return variantResults ? asCaseResults(variantResults) : undefined;
    },
    getOfferImpact: (offerId: string) =>
      c.results?.settlementImpacts?.find(
        (impact) => impact.offerId === offerId
      ),
    getPartyStats: (partyId) =>
      c.results?.partyStats?.find((stats) => stats.partyId === partyId),
  };
  return <CaseContext.Provider value={value}>{children}</CaseContext.Provider>;
};

function asCaseResults(results: Results | undefined): CaseResults {
  const evMatrix = new EvMatrix(results?.evs ?? {});
  const settlementData: SettlementDataOfParty[] = results?.settlementData ?? [];
  return {
    getPartyDescriptors: () => results?.partyDescriptors ?? {},
    getEvMatrix: () => evMatrix,
    getSettlementData: () => settlementData,
    getOutcomes: () => results?.outcomes ?? [],
    // base case has no bargaining data
    getBargainingData: () => results?.bargainingData ?? [],
  };
}

export function useCase() {
  return useContext(CaseContext);
}
