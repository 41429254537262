import Big from "big.js";
import { Stage } from "../analytics/model/CaseConfiguration";
import { Cost, NamedEntity } from "./CaseModel";
import { ZERO } from "./WeightedValue";

export interface Budget {
  partyId: string;
  costs: Costs[];
}
export interface Costs extends NamedEntity {
  stage: Stage;
  disbursements: Disbursements[];
  hourlyCosts: HourlyCosts[];
}

export interface HourlyCosts extends NamedEntity {
  ratePerHour: number;
  entries?: TimeSpent[];
}

export interface Disbursements extends NamedEntity {
  entries?: Disbursement[];
}

export interface CostEntry {
  readonly id: string;
  incurred?: boolean;
  note?: string;
  apportionment?: string[];
}

export interface TimeSpent extends CostEntry {
  hours: number;
}

export interface Disbursement extends CostEntry {
  amount: number;
  courtFee?: boolean;
}

export interface Totals {
  incurredDisbursements?: number;
  incurredTimeCosts?: number;
  estimatedDisbursements?: number;
  estimatedTimeCosts?: number;
}

export class OptionalBig {
  private value: Big | undefined = undefined;

  addBig(another: Big): OptionalBig {
    this.value = this.value !== undefined ? this.value.add(another) : another;
    return this;
  }

  mulBig(another: Big): OptionalBig {
    if (this.value !== undefined) this.value = this.value.mul(another);
    return this;
  }

  mulNumber(another: number): OptionalBig {
    if (this.value !== undefined) this.value = this.value.mul(another);
    return this;
  }

  addNumber(another: number): OptionalBig {
    this.value =
      this.value !== undefined ? this.value.add(another) : new Big(another);
    return this;
  }

  add(another: OptionalBig): OptionalBig {
    if (another.value === undefined) return this;
    this.value =
      this.value !== undefined ? this.value.add(another.value) : another.value;
    return this;
  }

  toNumber = () => this.value?.toNumber();

  or = (alternative: number) => this.toNumber() || alternative;
}

export class Total {
  private incurredDisbursements = new OptionalBig();
  private estimatedDisbursements = new OptionalBig();
  private incurredTimeCosts = new OptionalBig();
  private estimatedTimeCosts = new OptionalBig();

  addIncurredDisbursement(value: number) {
    this.incurredDisbursements.addNumber(value);
  }
  addEstimatedDisbursement(value: number) {
    this.estimatedDisbursements.addNumber(value);
  }
  addIncurredTimeCosts(value: number) {
    this.incurredTimeCosts.addNumber(value);
  }
  addEstimatedTimeCosts(value: number) {
    this.estimatedTimeCosts.addNumber(value);
  }

  add(another: Total) {
    this.incurredDisbursements.add(another.incurredDisbursements);
    this.estimatedDisbursements.add(another.estimatedDisbursements);
    this.incurredTimeCosts.add(another.incurredTimeCosts);
    this.estimatedTimeCosts.add(another.estimatedTimeCosts);
  }

  getIncurredDisbursements() {
    return this.incurredDisbursements.toNumber();
  }
  getEstimatedDisbursements() {
    return this.estimatedDisbursements.toNumber();
  }
  getIncurredTimeCosts() {
    return this.incurredTimeCosts.toNumber();
  }
  getEstimatedTimeCosts() {
    return this.estimatedTimeCosts.toNumber();
  }

  getGrandTotal(): number {
    return (
      (this.incurredDisbursements.toNumber() || 0) +
      (this.estimatedDisbursements.toNumber() || 0) +
      (this.incurredTimeCosts.toNumber() || 0) +
      (this.estimatedTimeCosts.toNumber() || 0)
    );
  }
}

export function convert(costs: Costs): Cost[] {
  const costArray: Cost[] = [];
  costs.disbursements.forEach((disbursements) => {
    disbursements.entries?.forEach((disbursement) => {
      costArray.push({
        title: disbursements.name,
        value: disbursement.amount,
        incurred: disbursement.incurred,
      });
    });
  });
  costs.hourlyCosts.forEach((hourlyCosts) => {
    hourlyCosts.entries?.forEach((timeSpent) => {
      costArray.push({
        title: hourlyCosts.name,
        value: timeSpent.hours * hourlyCosts.ratePerHour,
        incurred: timeSpent.incurred,
      });
    });
  });
  return costArray;
}

export function getTotal(costs: Costs): Total {
  const total = new Total();

  costs.disbursements.forEach((d) => {
    d.entries?.forEach((entry) => {
      if (entry.incurred) total.addIncurredDisbursement(entry.amount);
      else total.addEstimatedDisbursement(entry.amount);
    });
  });

  costs.hourlyCosts.forEach((c) => {
    c.entries?.forEach((entry) => {
      if (entry.incurred)
        total.addIncurredTimeCosts(c.ratePerHour * entry.hours);
      else total.addEstimatedTimeCosts(c.ratePerHour * entry.hours);
    });
  });

  return total;
}

export function getTotalDisbursements(disbursements: Disbursement[] = []): Big {
  let total = ZERO;
  disbursements.forEach(
    (disbursement) => (total = total.add(disbursement.amount))
  );
  return total;
}

export function getTotalHours(times: TimeSpent[] = []): Big {
  let totalHours = ZERO;
  times.forEach((time) => (totalHours = totalHours.add(time.hours)));
  return totalHours;
}
