import React from "react";
import {
  Case,
  Claim,
  Configuration,
  Party,
  SettlementOffer,
} from "../../model/CaseModel";
import { Budget } from "../../model/CostModel";

export interface CaseApi {
  queryAllCases(): Promise<Case[]>;
  queryCase(caseId: string): Promise<Case>;
  addCase(c: Case): Promise<Case>;
  removeCase(caseId: string): Promise<void>;
  setTitle(caseId: string, title: string): Promise<void>;
  addParty(caseId: string, party: Party): Promise<Party[]>;
  updateParty(caseId: string, prototype: Party): Promise<void>;
  removeParty(caseId: string, partyId: string): Promise<void>;
  addClaim(caseId: string, claim: Claim): Promise<Claim[]>;
  updateClaim(caseId: string, claim: Claim): Promise<void>;
  removeClaim(caseId: string, claimId: string): Promise<void>;
  addBudget(caseId: string, budget: Budget): Promise<Budget[]>;
  updateBudget(caseId: string, budget: Budget): Promise<void>;
  removeBudget(caseId: string, partyId: string): Promise<void>;
  // settlement offers
  addOffer(caseId: string, offer: SettlementOffer): Promise<SettlementOffer[]>;
  updateOffer(caseId: string, offer: SettlementOffer): Promise<void>;
  removeOffer(caseId: string, offerId: string): Promise<void>;

  setBargainingVariant(caseId: string, variant?: Configuration): Promise<void>;
}

export const CaseApiContext = React.createContext<CaseApi>({
  queryAllCases: function (): Promise<Case[]> {
    throw new Error("Not initialized");
  },
  queryCase: function (): Promise<Case> {
    throw new Error("Not initialized");
  },
  addCase: function (): Promise<Case> {
    throw new Error("Not initialized");
  },
  removeCase: function (): Promise<void> {
    throw new Error("Not initialized");
  },
  setTitle: function (): Promise<void> {
    throw new Error("Not initialized");
  },
  addParty: function (): Promise<Party[]> {
    throw new Error("Not initialized");
  },
  updateParty: function (): Promise<void> {
    throw new Error("Not initialized");
  },
  removeParty: function (): Promise<void> {
    throw new Error("Not initialized");
  },
  addClaim: function (): Promise<Claim[]> {
    throw new Error("Not initialized");
  },
  updateClaim: function (): Promise<void> {
    throw new Error("Not initialized");
  },
  removeClaim: function (): Promise<void> {
    throw new Error("Not initialized");
  },
  addBudget: function (): Promise<Budget[]> {
    throw new Error("Not initialized");
  },
  updateBudget: function (): Promise<void> {
    throw new Error("Not initialized");
  },
  removeBudget: function (): Promise<void> {
    throw new Error("Not initialized");
  },
  addOffer: function (): Promise<SettlementOffer[]> {
    throw new Error("Not initialized");
  },
  updateOffer: function (): Promise<void> {
    throw new Error("Not initialized");
  },
  removeOffer: function (): Promise<void> {
    throw new Error("Not initialized");
  },
  setBargainingVariant: function (): Promise<void> {
    throw new Error("Not initialized");
  },
});
