import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const stages = {
  PreAction: "Pre-action",
  IssueStatementsOfCase: "Issue / statements of case",
  CMC: "CMC",
  Disclosure: "Disclosure",
  WitnessStatements: "Witness statements",
  ExpertReports: "Expert reports",
  PTR: "PTR",
  TrialPreparation: "Trial preparation",
  Trial: "Trial",
  Appeals: "Appeals",
  Enforcement: "Enforcement",
};

const translationEn = {
  case: {
    stages,
    budget: {
      title: "Costs",
      noBudget: "No cost configuration for {{name}}",
      costCopyDialog: {
        title: "Copy costs",
        description:
          "Costs of {{target}} can be populated with values of another party. Please note that this will overwrite any costs currently configured for {{target}}.",
        source: {
          description: "Select party to copy costs of",
          label: "Copy from",
          empty: "Source party must be selected",
        },
        cancel: "Cancel",
        submit: "Copy costs",
      },
      works: {
        preAction: "Pre-action costs",
        issueStatementsOfCase: "Issue / statements of case",
        cmc: "CMC",
        disclosure: "Disclosure",
        witnessStatements: "Witness statements",
        expertReports: "Expert reports",
        ptr: "PTR",
        trialPreparation: "Trial preparation",
        trial: "Trial",
        adr: "ADR / Settlement discussions",
        contingentA: "Contingent cost A",
        contingentB: "Contingent cost B",
        enforcement: "Enforcement",
        appeals: "Appeals",
      },
      apportionment: {
        description:
          "Please specify the apportionment of the cost towards the case parties",
        switch: "Equal apportionment towards each party",
        tip: {
          switch: "Is the cost apportioned equally towards all parties?",
        },
        parties: "Select parties",
        helperText: {
          emptyApportionment: "At least one party must be selected",
        },
      },
      table: {
        header: {
          stage: "Work done",
          incurred: "Incurred",
          estimated: "Estimated",
          total: "Total",
          disbursements: "Disbursements",
          timeCosts: "Time costs",
        },
        grandTotal: "Grand total",
        edit: "Edit",
        cancel: "Cancel",
        save: "Save",
        copy: "Copy from other party",
        error: {
          save: "Saving costs failed",
        },
      },
      stage: {
        header: {
          rate: "Rate",
          incurredCosts: "Incurred Costs",
          incurredLawyerCosts: "Incurred Law Firm Fees",
          incurredFees: "Incurred Disbursements",
          incurredHours: "Incurred Hours",
          estimatedCosts: "Estimated Costs",
          estimatedLawyerCosts: "Estimated Law Firm Fees",
          estimatedFees: "Estimated Disbursements",
          estimatedHours: "Estimated Hours",
          total: "Total",
          disbursements: "Disbursements",
          totalDisbursements: "Total disbursements",
          timeCosts: "Time costs",
          totalTimeCosts: "Total time costs",
        },
      },
      hourlyCosts: {
        title: "Time Costs of '{{taskName}}'",
        subTitle: "{{costsName}} for {{context}}",
        subTitle_no_party: "{{costsName}}",
        incurred: "Incurred Costs",
        estimated: "Estimated Costs",
        element: {
          primary: "{{amount}} ({{count}} hours)",
          primary_one: "{{amount}} (1 hour)",
          primary_zero: "{{amount}}",
        },
        totalCost: "Total Costs",
        totalHours: "Total Hours",
        deleteDialog: {
          title: "Delete costs?",
          title_one: "Delete cost?",
          description:
            "Do you wish to remove the selected {{count}} cost entries?",
          description_one: "Do you wish to remove the selected cost entry?",
        },
        tip: {
          add: "Add new cost entry",
          rate: "Hourly rate",
          list: "Time costs are declared as hours spent. Each resulting cost entry is categorized as incurred or estimated. The + button below the list creates new entries.",
        },
      },
      disbursements: {
        title: "'{{taskName}}' Disbursements",
        subTitle: "{{costsName}} for {{context}}",
        subTitle_no_party: "{{costsName}}",
        incurred: "Incurred Costs",
        estimated: "Estimated Costs",
        totalCost: "Total Costs",
        deleteDialog: {
          title: "Delete costs?",
          title_one: "Delete cost?",
          description:
            "Do you wish to remove the selected {{count}} cost entries?",
          description_one: "Do you wish to remove the selected cost entry?",
        },
        tip: {
          add: "Add new cost entry",
          list: "Cost entries are categorized as incurred or estimated. The + button below the list creates new entries.",
        },
      },
      disbursement: {
        editDialog: {
          sections: {
            amount: {
              label: "1. Amount",
              description:
                "Please specify the value of '{{taskName}}' disbursement",
              incurred: "Incurred",
              tip: {
                amount: "Amount spent",
                note: "Note",
                incurred: "Is the cost incurred?",
              },
              result: "Resulting cost",
            },
          },
          title: "Disbursement Editor",
          cancel: "Cancel",
          submit: "Save Cost",
        },
      },
      timeSpent: {
        editDialog: {
          sections: {
            hours: {
              label: "1. Time",
              description:
                "Please specify the amount of time spent on '{{taskName}}'",
              incurred: "Incurred",
              tip: {
                hours: "Hours spent",
                note: "Note",
                incurred: "Is the cost incurred?",
              },
              rate: "Rate per hour",
              result: "Resulting cost",
            },
            apportionment: {
              label: "2. Apportionment",
              description:
                "Please specify the apportionment of the cost towards the case parties",
              switch: "Equal apportionment towards each party",
              tip: {
                switch: "Is the cost apportioned equally towards all parties?",
              },
              parties: "Select parties",
              helperText: {
                emptyApportionment: "At least one party must be selected",
              },
            },
          },
          title: "Time Costs Editor",
          cancel: "Cancel",
          submit: "Save Cost",
        },
      },
    },
    fetchError: {
      header: "An error ocurred while loading case data",
      errorMessage: "{{error.message}}",
    },
    creator: {
      label: "Create new case",
      title: "Title",
      titleHelperText: "Title cannot be empty",
      submit: "Create",
      cancel: "Cancel",
    },
    title: {
      editDialog: {
        title: "Change case title",
        description: "Please specify the title of the case",
        cancel: "Cancel",
        submit: "Save title",
        tip: {
          title: "Title of the case",
        },
        helper: {
          emptyTitle: "Title must not be empty",
        },
        error: {
          save: "Failed to save case title",
        },
      },
    },
    parties: {
      title: "Parties",
      name: {
        client: "{{name}} (Client)",
        noClient: "{{name}}",
      },
      deleteDialog: {
        title: "Confirm delete",
        description: "Do you wish to delete party '{{partyName}}'?",
      },
      tip: {
        add: "Add new party to this case",
      },
      editDialog: {
        title: "Party editor",
        description:
          "Please specify the party's name. Further, a party can be marked as client.",
        cancel: "Cancel",
        submit: "Save party",
        tip: {
          name: "Name of the party",
          client: "Select if party is your client",
        },
        helperText: {
          emptyName: "Name must not be empty",
        },
        client: {
          yes: "Party is a client",
          no: "Party is not a client",
        },
      },
      assets: {
        value: "{{value}} in assets",
        unlimited: "Unlimited assets",
        deleteDialog: {
          title: "Confirm delete",
          description: "Do you wish to delete the asset '{{name}}'?",
        },
        editDialog: {
          title: "Asset editor",
          description:
            "Please specify a name and a value estimate for the asset. It is possible to specify multiple weighted estimates to reflect different probabilities of the asset having a value.",
          cancel: "Cancel",
          submit: "Save asset",
          tip: {
            name: "Name of the asset",
          },
          helper: {
            emptyName: "Name must not be empty",
            valueMissing:
              "Please use the + button to estimate the value of this asset",
          },
        },
        tip: {
          add: "Add new asset",
        },
      },
      claimants: "Claimants:",
      defendants: "Defendants:",
      add: "Add party",
      wizard: {
        confirm: "Add",
        cancel: "Cancel",
        name: "Name",
        lawyer: "Represented by",
        title: "Add new party to the case",
        helpText: {
          name: "Name of the party",
          lawyer: "Lawyer representing the party",
        },
      },
    },
    recoveries: {
      details: {
        percentage: "of cost awarded",
        type: {
          LawyerCosts: "Law Firm Fees",
          CourtFees: "Disbursements",
        },
        total: {
          LawyerCosts: "Total Law Firm Fees",
          CourtFees: "Total disbursements",
        },
        result: "Resulting recovery",
      },
      title: "Cost recoveries",
      description:
        "Please specify the likely % of awarded cost recoveries per party.",
      description_zero: "No parties defined in case.",
      editDialog: {
        title: "Cost recovery editor",
        description:
          "Please specify the payers and recipients of the cost recoveries. Further, please specify what type and % of the awarded recoveries.",
        tip: {},
        helperText: {
          wrongPayers: "At least one payer must be selected",
          wrongRecipients: "At least one recipient must be selected",
        },
        label: {
          payers: "Payers",
          recipients: "Recipients",
          noPayers: "No payers available",
          noRecipients: "No recipients available",
        },
        percentage: "% of cost awarded",
        cancel: "Cancel",
        submit: "Save recovery",
        type: {
          LawyerCosts: "Law Firm Fees",
          CourtFees: "Disbursements",
        },
      },
    },
    apportionments: {
      title: "Cost apportionments",
      description:
        "Please specify the apportionment of costs per party by distributing the total of 100% among parties. If less than 100% is apportioned towards the opponents, the remaining % are considered generic costs and allocated equally towards all opponents.",
      description_zero:
        "Apportionment is configurable only for parties with two or more opponents.",
      party: {
        description:
          "{{name}}'s costs apportionment towards opponents in case.",
        description_one:
          "{{name}}'s costs apportionment towards the opponent in case.",
        description_zero: "{{name}} has no opponents in case.",
      },
      results: "Resulting per-party apportionments, including generic costs.",
      recoveries:
        "Resulting per-party liability for the awarded recoveries, assuming {{name}} wins all claims.",
      generic: "Generic costs",
      perParty: "Towards {{name}}",
      details: {
        percentage: "of costs",
      },
      error: { updateFailed: "Failed to update apportionment: {{error}}" },
      chart: {
        label: {
          apportionedCosts: "Apportionment",
          genericCosts: "Generic costs",
        },
        totalValue: "Total: {{value}}",
      },
    },
    claims: {
      title: "Claims",
      subClaims: "Issues",
      header: "{{claimOrdinal}} Claim: {{title}}",
      claimant: "Claimant: {{name}}",
      defendants: "Defendants: {{names}}",
      defendants_zero: "No defendants configured",
      defendants_one: "Defendant: {{names}}",
      add: "Add claim",
      winChance: {
        valueLabel: "Win chance {{winChance}}",
        tooltip: "Claimant's win chance v {{defendant}}",
        tooltipNoDefendant: "Claimant's win chance",
        notInvolved: "Not involved",
      },
      quantums: {
        maxValue: "{{value}} in claimed quantums",
        maxValue_zero: "No quantums claimed",
        value: "{{value}} estimated award value",
        deleteDialog: {
          title: "Confirm quantum removal",
          description: "Do you wish to remove quantum '{{name}}' from claim?",
        },
        tip: { add: "Add quantum to claim" },
        liability: {
          editDialog: {
            title: "{{defendant}}'s liability for '{{quantumTitle}}'",
            subTitle: "{{defendant}}'s liability",
            issueLabel: "Issue {{issueOrdinal}}",
            issueDescription: "{{issueTitle}}",
            quantumLabel: "Quantum '{{quantumTitle}}'",
            quantumDescription: "Total claimed loss: {{quantumAmount}}",
            hint: "Please specify the monetary amount {{defendant}} is liable for '{{quantumTitle}}'",
            submit: "Save liability",
            cancel: "Cancel",
            sliderLabel: "Liability",
            textFieldLabel: "Liability amount",
          },
          value: "{{percentage}} ({{amount}})",
          none: "No liability",
          tooltip: "{{defendant}}'s liability for {{quantumTitle}} ",
        },
        editDialog: {
          title: "Quantum editor",
          sections: {
            name: {
              title: "1. Name",
              label: { name: "Quantum name *" },
              description: "Please select a name of the quantum.",
              tip: {
                name: "Quantum name",
              },
              helperText: {
                emptyName: "Name must not be empty",
                nameAlreadyUsed: "Quantum already defined in claim",
              },
            },
            value: {
              title: "2. Value",
              label: { value: "Claim value" },
              description: "Please specify the value of the claim.",
              tip: {
                value: "Quantum value",
              },
              helperText: {
                valueEmpty: "Value must be provided",
                valueNegative: "Value must not be negative",
              },
            },
            award: {
              title: "3. Judgment",
              description:
                "Please estimate the awarded quantum, assuming the claim is successful.",
              judgement: "Awarded value",
            },
          },
          cancel: "Cancel",
          submit: "Save quantum",
        },
      },
      editDialog: {
        title: "Claim editor",
        description:
          "Please specify a title, the claimant party as well as the defendants of the claim.",
        tip: {
          title: "Claim title",
        },
        helperText: {
          missingTitle: "Title must not be empty",
          missingClaimant: "Claiming party must be selected",
          wrongDefendants: "Defendants must not overlap with claimant",
        },
        label: {
          claimant: "Claimant",
          defendants: "Defendants",
          noParties: "No parties defined in case",
        },
        cancel: "Cancel",
        submit: "Save claim",
      },
      issues: {
        label: "Issues",
        defendants: "Defendants: {{names}}",
        defendants_one: "Defendant: {{names}}",
        defendants_zero: "No defendants",
        tip: {
          add: "Add new issue",
        },
        summary: {
          issueWinChance: {
            tooltip: {
              vAny: "Chance of winning issue {{issueOrdinal}} against at least one of defendants",
              vAll: "Chance of winning issue {{issueOrdinal}} against all defendants",
            },
            label: {
              vAny: "Issue {{issueOrdinal}} v any",
              vAll: "Issue {{issueOrdinal}} v all",
            },
          },
        },
        header: {
          issue: "<bold>{{ordinal}}</bold> Issue",
          sub_issue: "<bold>{{firstOrdinal}}{{secondOrdinal}}</bold>",
          group: "<bold>{{ordinal}}</bold> Grouped issue",
        },
        groups: {
          infoDialog: {
            title: {
              Or: "'And / Or' Issue Group",
              Xor: "'Either Or' Issue Group",
            },
            description: {
              Or: "In this <bold>And / Or</bold> group, any number of the sub-issues ({{groupedOrdinals}}) can be won at the same time. However, only one needs to be won for the parent issue {{ordinal}} to be considered won.",
              Xor: "In this <bold>Either Or</bold> group, the sub-issues ({{groupedOrdinals}}) are logically exclusive alternatives. One and only one of the sub-issues can be won. Winning one of the sub-issues invalidates the remaining sub-issues of the group.",
            },
            submit: "OK",
          },
        },
        relations: {
          name: {
            And: "And",
            Or: "And / Or",
            Xor: "Or",
          },
          hint: {
            And: "All issues must be won",
            Or: "One or more issues can be won",
            Xor: "One and only one issue can be won. The win chance sum of either-or issues cannot exceed 100%.",
          },
        },
        deleteDialog: {
          title: "Confirm delete",
          description: "Do you wish to remove issue {{issueOrdinal}}?",
        },
        quantums: {
          deleteDialog: {
            title: "Confirm delete",
            description:
              "Do you wish to remove quantum '{{name}}' from issue {{issueOrdinal}}?",
          },
        },
      },
      issueDialog: {
        title: "Issue in claim '{{parentClaim}}'",
        parentDefendants: "Same defendants as in claim",
        genericIssue: "Generic issue",
        sections: {
          title: {
            label: "1. Title",
            description: "Please specify a title of the issue.",
          },
          genericIssue: {
            label: "2. Issue type",
            description:
              "Generic issues represent general facts, not dependant upon individual defendants. They are won once against all liable defendants. Defendant-specific issues must be won per-defendant. Winning against one of the defendants is independent from winning against others.",
          },
          defendants: {
            label: "3. Defendants",
            description:
              "Issue is claimed by {{claimant}}. Please select the defendants of the issue.",
          },
        },
        tip: {
          title: "Issue title",
          parentDefendants: "Switch off to specify differing defendants",
          genericIssue:
            "Switch between generic and defendant-dependant issue type",
        },
        helperText: {
          missingTitle: "Title must not be empty",
        },
        label: {
          defendants: "Defendants",
          noParties: "No parties available as defendants",
        },
        cancel: "Cancel",
        submit: "Save issue",
      },
      deleteDialog: {
        title: "Confirm delete",
        description: "Do you wish to delete the claim '{{claim}}'?",
      },
      tip: {
        add: "Add new claim",
        edit: "Edit claim title and parties",
        delete: "Remove claim from case",
      },
    },
    costs: {
      title: "Costs",
      budgeted: "Budgeted costs",
      incurred: "Incurred costs",
      total: "Total costs",
      lawyers: "Law Firm Fees",
      courtFees: "Disbursements",
    },
    analytics: {
      name: {
        client: "{{name}} (Client)",
        noClient: "{{name}}",
      },
      ev: {
        description:
          "Average value of the case per party and stage of the case.",
        warning: {
          negative: "Negative average case value for {{name}}",
        },
        ev: {
          neoData: "No case value data available",
        },
      },
      netResult: {
        description:
          "Post-enforcement net results per party, averaged across {{count}} possible judgment outcomes.",
        description_zero:
          "Net results cannot be calculated yet, case has no judgment outcomes.",
        description_one:
          "Post-enforcement net results per party for the single possible judgment outcome.",
        warning: {
          negative: "Negative average net result for {{name}}",
        },
        label: "Net result",
        ev: {
          noData: "No results available",
        },
      },
      risk: {
        noOutcomes: "No case outcomes available for {{name}}",
        description: "Case outcomes from {{name}}'s perspective",
        description_one: "Case outcome from {{name}}'s perspective",
        description_zero:
          "Risk analysis not available, case has no judgment outcomes.",
        summary: {
          total: "Total",
          negative: "Negative",
          negativeProbability: "Probability of negative outcome",
          worst: "Worst",
          best: "Best",
          variance: "Var",
          variancePositive: "Var+",
          varianceNegative: "Var-",
          standardDeviation: "Standard deviation",
          standardDeviationToEv: "As %",
          standardDeviationPositive: "Standard deviation of positive results",
          standardDeviationPositiveToEv: "As %",
          standardDeviationNegative: "Standard deviation of negative results",
          standardDeviationNegativeToEv: "As %",
          riskScore: "Risk score (Var+/Var-)",
        },
        warning: {
          assetLimit:
            "In {{count}} outcome scenarios with the total probability of {{probability}} the assets of {{name}} are depleted",
          assetLimit_one:
            "In one outcome scenario with the probability of {{probability}} the assets of {{name}} are depleted",
        },
      },
      settlement: {
        bargaining: {
          configurationTabs: {
            winChance: "Win chances",
            costs: "Costs",
            assets: "Assets",
          },
          title: "Bargaining simulation",
          claim: "Claim: {{title}}",
          description:
            "Modify the case parameters to simulate and analyze alternative perspectives on the settlement range.",
          descriptionOpponentVariant:
            "Resulting bargaining range for {{name}} v. {{other}} at '{{stage}}' stage",
          sections: {
            claims: "Claims",
          },
          evOfParty: "{{name}}'s case value",
          midpoint: "Midpoint",
          chart: {
            baseline: "Baseline",
            variant: "Modified variant",
          },
          valueLabels: {
            unchanged: "No change",
            unlimited: "Limit removed",
            limited: "Limit set",
          },
          buttons: {
            add: {
              settlement: {
                label: "Add offer",
                tooltip:
                  "Create a settlement offer of {{name}} for {{opponent}}",
              },
            },
            unlimited: {
              assets: {
                label: "Unlimited",
                tooltip: "Assume unlimited assets for {{name}}",
              },
            },
            minus: {
              assets: {
                tooltip: "Reduce {{name}}'s asset value by {{step}}",
              },
              costs: {
                tooltip: "Reduce {{name}}'s costs by {{step}}",
              },
            },
            plus: {
              assets: {
                tooltip: "Increase {{name}}'s asset value by {{step}}",
              },
              costs: {
                tooltip: "Increase {{name}}'s costs by {{step}}",
              },
            },
            reset: {
              assets: {
                tooltip: "Reset {{name}}'s asset value to baseline",
              },
              costs: {
                tooltip: "Reset {{name}}'s costs to baseline",
              },
              winChance: {
                label: "Reset to baseline",
                tooltip: "Reset win chance to baseline",
              },
              all: {
                label: "Reset to baseline",
                tooltip: "Reset all modified values to baseline",
              },
            },
          },
        },
        offerDialog: {
          stages,
          title: "Settlement offer by {{offeror}}",
          tabs: {
            payment: "Payment",
            claims: "Claims",
            apportionment: "Apportionment",
            results: "Results",
          },
          sections: {
            payment: {
              offer: {
                title: "Settlement sum",
              },
              costs: {
                title: "Settlement costs",
              },
            },
            claims: {
              title: "Settled claims",
              legend: "Claims settled by the offer",
            },
            offer: {
              title: "Settlement sum",
            },
            apportionment: {
              costs: {
                title: "Costs",
              },
              quantums: {
                title: "Quantums",
              },
              summary: {
                title: "Summary",
              },
            },
            status: {
              title: "Status",
            },
          },
          costs: {
            offeror: "{{name}}'s settlement costs",
            offeree: "{{name}}'s settlement costs",
            towardsPayer: "Costs towards {{opponent}}",
            settlement: "Settlement costs",
            other: "Remaining costs",
          },
          error: {
            noClaimSelected: "Please select at least one claim to settle",
          },
          offeror: "Offeror: {{name}}",
          offeree: "Offeree: {{name}}",
          cancel: "Cancel",
          delete: "Delete",
          submit: "Save",
          stage: "At what stage of case?",
          direction: {
            label: "Who pays whom",
            offereePays: "{{offeree}} pays {{offeror}}",
            offerorPays: "{{offeror}} pays {{offeree}}",
          },
          status: {
            draft: "Draft",
            offered: "Offered",
            rejected: "Rejected",
            accepted: "Accepted",
          },
          amount: "Offer amount",
        },
        offerSummary: {
          baseData: {
            offeror: "Offeror {{name}}",
            offeree: "Offeree {{name}}",
            amount: "Offer amount",
            costs: "Settlement costs",
            balance: "Offer balance",
          },
          ev: {
            offer: "Offer of {{amount}}",
            floor: "Expected floor v {{name}}",
            mid: "Settlement midpoint",
            ceiling: "Expected ceiling v {{name}}",
            withoutOffer: "No settlement",
            delta: "Difference",
          },
          netResult: {
            offeror: "Net result {{name}}",
            offeree: "Net result {{name}}",
            withOffer: "Offer",
            withoutOffer: "No settlement",
            delta: "Difference",
          },
        },
        deleteOfferDialog: {
          title: "Delete offer",
          question:
            "Are you sure to remove the settlement offer made by {{offeror}}?",
          buttons: {
            delete: "Delete",
            cancel: "Cancel",
          },
        },
        new: "New offer",
        description: "Settlement offers made and received by {{name}}.",
        titleSettlementChart: "{{name}} v. {{other}}",
        noOutcomes:
          "Settlement analytics not available, case has no outcomes yes",
        noData: "No settlement data available",
        noIssue: "No issues between parties",
        chart: {
          labels: {
            evSettlingParty: "{{settlingParty}}'s case value v. {{otherParty}}",
            evOther: "{{otherParty}}'s inverse case value v. {{settlingParty}}",
            midpoint: "Midpoint",
            netResult: "Net result without settlement",
            worstEnforcement: "Worst-case enforcement for {{settlingParty}}",
            bestEnforcement: "Best-case enforcement for {{settlingParty}}",
          },
        },
      },
      sensitivity: {
        description:
          "Following charts simulate the impact of variable changes on the value of the case, from {{name}}'s perspective.",
        variables: {
          claimWinChance: "Win chance for {{claim}}",
          costs: "Costs of {{name}}",
          assets: "Assets of {{name}}",
          ev: "Value of case",
        },
      },
      outcomes: {
        description: "Judgment outcomes from {{name}}'s perspective.",
        description_zero: "Case has no judgment outcomes.",
        judgments: {
          description:
            "Possible case outcomes, grouped and sorted by their net result, from lowest to highest:",
          number: "{{numberOfJudgments}} judgments",
          number_one: "Single judgment",
        },
        summary: {
          judgments: {
            total: "Total judgments",
            negative: "With negative value",
            negativeProbability: "Probability of negative judgment",
          },
          outcomes: {
            total: "Resulting monetary outcomes",
            negative: "With negative net result",
            negativeProbability: "Probability of negative net result",
          },
          alerts: {
            allNegative: "All monetary outcomes for {{name}} are negative",
            mostNegative:
              "{{name}} has {{probability}} chance of a negative monetary outcome",
          },
        },
        tooltip: {
          value: "Judgment value",
        },
        enforcement: {
          value: "Enforceable",
          costs: "Enforcement costs",
        },
        netResult: "Net result: {{amount}}",
        warning: {
          debt: "{{value}} debt after enforcement",
          enforcementValue: "{{value}} not enforceable",
        },
      },
    },
    details: {
      section: {
        configuration: {
          parties: "Parties",
          claims: "Claims",
          costs: "Costs",
          apportionments: "Apportionment",
          recoveries: "Recoveries",
        },
        analytics: {
          value: "Case value",
          netResults: "Net results",
          risk: "Risk analysis",
          settlement: "Settlements",
          bargaining: "Bargaining",
          sensitivity: "Sensitivity",
          outcomes: "Case outcomes",
          tree: "Decision tree",
        },
      },
      caseId: "Case '{{caseId}}'",
      createdAt: "Case created: {{date}}",
      updatedAt: "Last update: {{date}}",
      label: {
        configuration: "Configuration",
        analytics: "Analytics",
      },
      undefined: "Loading case data...",
      noAnalytics: "Please configure parties and claims to enable analytics.",
    },
    list: {
      fetching: "Loading cases...",
      label: "Cases",
      card: {
        caseId: "Case '{{caseId}}'",
        parties: "Parties: {{claimants}}",
        createdAt: "Created: {{date}}",
        updatedAt: "Last update: {{date}}",
        open: "Open",
        delete: "Delete",
      },
      new: "New Case",
      deleteDialog: {
        title: "Delete case '{{id}}'?",
        question: "Do you wish to irreversibly delete case '{{name}}'?",
        delete: "Delete",
        cancel: "Cancel",
      },
    },
  },
  general: {
    CheckboxList: {
      noElements: "No elements",
      all: "All",
    },
  },
  login: {
    title: "Sign In",
    submit: "Sign In",
    label: {
      email: "Username or Email",
      password: "Password",
      remember: "Remember Me",
    },
    error: {
      loginFailed: "Login failed",
    },
  },
  loading: {
    headline: "Loading ...",
  },
  appBar: {
    title: "CaseSolv",
    logout: "Sign out",
  },
  weightedValue: {
    label: {
      value: "Value",
      weight: "Weight | Probability",
      result: "Resulting average value",
    },
    tip: {
      button: {
        delete: "Delete value estimate",
        add: "Add new value estimate",
      },
      share: "Resulting probability of value estimate",
      weight: "Weight of this value in relation to other estimates",
    },
  },
  weightedAwards: {
    label: {
      award: "Award {{index}}",
      value: "Value",
      weight: "Weight | Probability",
      result: "Resulting award estimate",
      percentage: "{{percentage}} of claim",
      button: {
        add: "Add award estimate",
      },
    },
    tip: {
      button: {
        delete: "Delete award estimate",
        add: "Add new award estimate",
      },
      share: "Resulting probability of award estimate",
      weight: "Weight of this award in relation to other estimates",
    },
  },
  dialog: {
    yesNo: {
      yes: "Yes",
      no: "No",
    },
  },
  moreMenu: {
    tip: {
      edit: "Edit",
      hide: "Hide",
      delete: "Delete",
    },
  },

  tornado: {
    tooltip: {
      increase:
        "{{variable}} increased by {{delta}} to {{value}} (+{{percent}})",
      decrease:
        "{{variable}} decreased by {{delta}} to {{value}} (-{{percent}})",
    },
  },
  error: {
    header: "Oops!",
    message: "Sorry, an unexpected error has occurred.",
    status: "Status {{status}}: {{text}}",
    data: "",
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: translationEn,
      },
    },
  });

export default i18n;
