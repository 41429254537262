import { useTheme } from "@mui/material";
import { ChartData } from "chart.js";
import { t } from "i18next";
import { Bar } from "react-chartjs-2";
import { Stage } from "../../analytics/model/CaseConfiguration";
import { Currency, gbp } from "../../i18n/Currency";

export type StageCosts = {
  stage: Stage;
  incurred: number;
  estimated: number;
};

export const CostsBarChart = ({
  data,
  currency = gbp,
}: {
  data: StageCosts[];
  currency?: Currency;
}) => {
  const theme = useTheme();

  // get all clients into the chart
  // then, get defendants in, sorted by EV
  const chartData: ChartData<"bar", number[]> = {
    labels: data.map((stageCosts) =>
      t("case.stages." + Stage[stageCosts.stage])
    ),
    datasets: [
      {
        label: "" + t("case.budget.table.header.incurred"),
        data: data.map((stageCosts) => stageCosts.incurred),
        backgroundColor: theme.palette.primary.main + "90",
        borderColor: theme.palette.primary.dark,
        borderWidth: 2,
      },
      {
        label: "" + t("case.budget.table.header.estimated"),
        data: data.map((stageCosts) => stageCosts.estimated),
        backgroundColor: theme.palette.secondary.main + "90",
        borderColor: theme.palette.secondary.dark,
        borderWidth: 2,
      },
    ],
  };

  const font = {
    family: theme.typography.fontFamily,
  };
  return (
    <Bar
      data={chartData}
      options={{
        interaction: {
          mode: "index",
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            bodyFont: font,
            titleFont: font,
            footerFont: font,
            callbacks: {
              footer: function (items) {
                let total = 0;
                items.forEach((item) => {
                  const dataset = chartData.datasets[item.datasetIndex];
                  const value = dataset.data[item.dataIndex];
                  total += value;
                });
                return "Total: " + currency.format(total);
              },
              label: (item) =>
                item.dataset.label + ": " + currency.format(item.parsed.y),
            },
          },
        },
        scales: {
          y: {
            ticks: {
              callback: function (value) {
                if (typeof value === "number") return currency.format(value);
                return value;
              },
              font,
            },
            grid: {
              // make 0 line thicker
              lineWidth: (context) => (context.tick.value == 0 ? 2 : 1),
            },
            stacked: true,
          },
          x: {
            ticks: {
              font,
            },
            grid: {
              color: () => undefined,
            },
            stacked: true,
          },
        },
      }}
    />
  );
};
