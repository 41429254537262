import { Gavel, Handshake, Iso, StackedBarChart } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Container,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { useLoaderData, useNavigate } from "react-router";
import { WeighedAverage } from "../../analytics/Value";
import { ZERO } from "../../big/bigUtil";
import { useCurrency } from "../../context/CurrencyContext";
import { Case, CaseStats } from "../../model/CaseModel";
import { Copyright } from "../Copyright";
import { percentage } from "../issues/Claims";

type PartyValueInCase = {
  caseId: string;
  partyId: string;
  value: number;
};

type Stat = {
  avg: WeighedAverage;
  max?: PartyValueInCase;
  min?: PartyValueInCase;
};

function update(stat: Stat, value: number, caseId: string, partyId: string) {
  stat.avg.add({ value });
  if (!stat.max || value > stat.max.value)
    stat.max = { caseId, partyId, value };
  if (!stat.min || value < stat.min.value)
    stat.min = { caseId, partyId, value };
}

const StatCard = ({
  title,
  subTitle,
  value,
  valueVariant = "h4",
  variant = "neutral",
  href,
}: {
  title: string;
  subTitle: string;
  variant?: "positive" | "negative" | "neutral";
  value: string;
  valueVariant?: Variant;
  href?: string;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Card
      elevation={3}
      sx={{
        width: "fit-content",
        userSelect: "none",
        borderTop: "solid 6px",
        borderTopColor:
          variant === "negative"
            ? theme.palette.error.dark
            : variant === "positive"
              ? theme.palette.success.main
              : theme.palette.text.secondary,
        cursor: href ? "pointer" : "auto",
      }}
      onClick={href ? () => navigate(href) : undefined}
    >
      <CardContent>
        <Typography variant="overline">{title}</Typography>
        <Typography
          textAlign="center"
          variant={valueVariant}
          fontWeight={theme.typography.fontWeightBold}
        >
          {value}
        </Typography>
        <Typography
          variant="subtitle2"
          textAlign="center"
          textOverflow="ellipsis"
        >
          {subTitle}
        </Typography>
      </CardContent>
    </Card>
  );
};

export const PortfolioPage = () => {
  const theme = useTheme();
  const { cases } = useLoaderData() as { cases: Case[] };
  const currency = useCurrency();

  const clientStats = new Map<string, CaseStats>();
  const allCases = new Map<string, Case>();
  const allParties = new Set<string>();
  const winChance: Stat = { avg: new WeighedAverage() };

  // quantums claimed by clients
  let totalQuantumsClaimed = 0;
  const quantumsClaimed: Stat = { avg: new WeighedAverage() };

  // quantums claimed against by clients
  let totalQuantumsClaimedAgainst = 0;
  const quantumsClaimedAgainst: Stat = { avg: new WeighedAverage() };

  // costs
  const estimatedCosts: Stat = { avg: new WeighedAverage() };
  const estimatedLawFees: Stat = { avg: new WeighedAverage() };
  const incurredCosts: Stat = { avg: new WeighedAverage() };
  const incurredLawFees: Stat = { avg: new WeighedAverage() };

  // net results & evs
  const netResults: Stat = { avg: new WeighedAverage() };
  const evs: Stat = { avg: new WeighedAverage() };

  // settlements
  let totalAcceptedSettlements = 0;
  let totalSettledOpponents = 0;
  let totalSettledClaims = 0;
  const settlementPaymentsToParty: Stat = { avg: new WeighedAverage() };
  const settlementPaymentsByParty: Stat = { avg: new WeighedAverage() };

  cases.forEach((c) => {
    allCases.set(c.id, c);
    c.parties?.forEach(({ id, client }) => {
      allParties.add(id);
      if (!client) return;
      const partyStats = c.results?.partyStats?.find(
        (stats) => stats.partyId === id
      );
      if (!partyStats) return;
      clientStats.set(id, partyStats.stats);

      // win chance
      update(
        winChance,
        1 - partyStats.stats.probabilityOfNegativeJudgement,
        c.id,
        id
      );

      // quantums claimed by clients
      totalQuantumsClaimed += partyStats.stats.quantumsClaimed.amount;
      update(
        quantumsClaimed,
        partyStats.stats.quantumsClaimed.totalValue,
        c.id,
        id
      );

      // quantums claimed against clients
      totalQuantumsClaimedAgainst +=
        partyStats.stats.quantumsClaimedAgainst.amount;
      update(
        quantumsClaimedAgainst,
        partyStats.stats.quantumsClaimedAgainst.totalValue,
        c.id,
        id
      );

      // costs
      const { disbursements, lawFirmFees } = partyStats.stats.costs;
      update(
        estimatedCosts,
        disbursements.estimated + lawFirmFees.estimated,
        c.id,
        id
      );
      update(estimatedLawFees, lawFirmFees.estimated, c.id, id);
      update(
        incurredCosts,
        disbursements.incurred + lawFirmFees.incurred,
        c.id,
        id
      );
      update(incurredLawFees, lawFirmFees.incurred, c.id, id);

      // net results & EVs
      update(netResults, partyStats.stats.netResult.average, c.id, id);
      update(evs, partyStats.stats.ev, c.id, id);

      // settlements
      const {
        accepted,
        paymentsToParty,
        paymentsByParty,
        settledClaims,
        settledOpponents,
      } = partyStats.stats.settlements;
      if (accepted > 0) {
        totalAcceptedSettlements += accepted;
        totalSettledClaims += settledClaims;
        totalSettledOpponents += settledOpponents;
        update(settlementPaymentsToParty, paymentsToParty, c.id, id);
        update(settlementPaymentsByParty, paymentsByParty, c.id, id);
      }
    });
  });

  // basic case stats
  // number of cases, number of clients, average win-chance, best/worst case links
  const numberOfCases = cases.length;
  const numberOfClients = clientStats.size;

  if (numberOfCases === 0) {
    return (
      <Typography variant="h6" mt={theme.spacing(4)}>
        No cases defined
      </Typography>
    );
  }
  if (numberOfClients === 0) {
    return (
      <Typography variant="h6" mt={theme.spacing(4)}>
        No clients
      </Typography>
    );
  }

  return (
    <Container
      maxWidth="lg"
      sx={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
      }}
    >
      <Typography variant="h4" my={theme.spacing(2)} gutterBottom>
        Portfolio analytics
      </Typography>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(1)}>
        <Gavel />
        <Typography>{`You work for ${numberOfClients} clients in ${allCases.size} cases`}</Typography>
      </Stack>
      <Stack flexWrap="wrap" direction="row" gap={theme.spacing(2)}>
        <StatCard
          key={"avg_win_chance"}
          title="Average win chance"
          value={percentage.format(winChance.avg.getOr(0))}
          subTitle={`in ${allCases.size} cases`}
        />
        {winChance.max && (
          <StatCard
            key={"best_win_chance"}
            href={`/cases/${winChance.max.caseId}/analytics/outcomes`}
            title="Best win chance"
            value={percentage.format(winChance.max.value)}
            subTitle={`${allCases.get(winChance.max.caseId)?.results?.partyDescriptors[winChance.max.partyId].name} in '${allCases.get(winChance.max.caseId)?.name}'`}
            variant="positive"
          />
        )}
        {winChance.min && (
          <StatCard
            key={"worst_win_chance"}
            href={`/cases/${winChance.min.caseId}/analytics/outcomes`}
            title="Lowest win chance"
            value={percentage.format(winChance.min.value)}
            subTitle={`${allCases.get(winChance.min.caseId)?.results?.partyDescriptors[winChance.min.partyId].name} in '${allCases.get(winChance.min.caseId)?.name}'`}
            variant="negative"
          />
        )}
      </Stack>
      <Divider />

      {/* net results */}
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(1)}>
        <Iso />
        <Typography>Net results</Typography>
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(2)}>
        <StatCard
          key={"avg_netResults"}
          title="Average net result"
          value={currency.format(netResults.avg.getOr(0))}
          subTitle={`per client`}
          valueVariant="h5"
        />
        {netResults.max && (
          <StatCard
            key={"max_netResults"}
            title="Highest net result"
            href={`/cases/${netResults.max.caseId}/analytics/netResults`}
            value={currency.format(netResults.max.value)}
            subTitle={`${allCases.get(netResults.max.caseId)?.results?.partyDescriptors[netResults.max.partyId].name} in '${allCases.get(netResults.max.caseId)?.name}'`}
            variant="positive"
            valueVariant="h5"
          />
        )}
        {netResults.min && (
          <StatCard
            key={"min_netResults"}
            title="Lowest net result"
            href={`/cases/${netResults.min.caseId}/analytics/netResults`}
            value={currency.format(netResults.min.value)}
            subTitle={`${allCases.get(netResults.min.caseId)?.results?.partyDescriptors[netResults.min.partyId].name} in '${allCases.get(netResults.min.caseId)?.name}'`}
            variant="negative"
            valueVariant="h5"
          />
        )}
      </Stack>
      <Divider />

      {/* costs */}
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(1)}>
        <StackedBarChart />
        <Typography>Costs of your clients</Typography>
      </Stack>
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={theme.spacing(2)}
        rowGap={theme.spacing(2)}
      >
        <StatCard
          key={"avg_incurredLawFees"}
          title="Average incurred law fees"
          value={currency.format(incurredLawFees.avg.getOr(0))}
          subTitle={`per client`}
          valueVariant="h5"
        />
        {incurredLawFees.max && (
          <StatCard
            key={"max_incurredLawFees"}
            title="Highest law fees incurred"
            href={`/cases/${incurredLawFees.max.caseId}/configuration/costs`}
            value={currency.format(incurredLawFees.max.value)}
            subTitle={`${allCases.get(incurredLawFees.max.caseId)?.results?.partyDescriptors[incurredLawFees.max.partyId].name} in '${allCases.get(incurredLawFees.max.caseId)?.name}'`}
            variant="positive"
            valueVariant="h5"
          />
        )}
        {incurredLawFees.min && (
          <StatCard
            key={"min_incurredLawFees"}
            title="Lowest law fees incurred"
            href={`/cases/${incurredLawFees.min.caseId}/configuration/costs`}
            value={currency.format(incurredLawFees.min.value)}
            subTitle={`${allCases.get(incurredLawFees.min.caseId)?.results?.partyDescriptors[incurredLawFees.min.partyId].name} in '${allCases.get(incurredLawFees.min.caseId)?.name}'`}
            variant="negative"
            valueVariant="h5"
          />
        )}
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(2)}>
        <StatCard
          key={"avg_estimatedLawFees"}
          title="Estimated law fees"
          value={currency.format(estimatedLawFees.avg.getOr(0))}
          subTitle={`per client`}
          valueVariant="h5"
        />
        {estimatedLawFees.max && (
          <StatCard
            key={"max_estimatedLawFees"}
            title="Highest law fees estimate"
            href={`/cases/${estimatedLawFees.max.caseId}/configuration/costs`}
            value={currency.format(estimatedLawFees.max.value)}
            subTitle={`${allCases.get(estimatedLawFees.max.caseId)?.results?.partyDescriptors[estimatedLawFees.max.partyId].name} in '${allCases.get(estimatedLawFees.max.caseId)?.name}'`}
            variant="positive"
            valueVariant="h5"
          />
        )}
        {estimatedLawFees.min && (
          <StatCard
            key={"min_estimatedLawFees"}
            title="Lowest law fees estimate"
            href={`/cases/${estimatedLawFees.min.caseId}/configuration/costs`}
            value={currency.format(estimatedLawFees.min.value)}
            subTitle={`${allCases.get(estimatedLawFees.min.caseId)?.results?.partyDescriptors[estimatedLawFees.min.partyId].name} in '${allCases.get(estimatedLawFees.min.caseId)?.name}'`}
            variant="negative"
            valueVariant="h5"
          />
        )}
      </Stack>
      <Divider />

      {/* settlements */}
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(1)}>
        <Handshake />
        <Typography>
          {totalAcceptedSettlements === 0
            ? "Your clients reached no settlements"
            : `Your clients reached ${totalAcceptedSettlements} settlement(s) to settle ${totalSettledClaims} claim(s) with ${totalSettledOpponents} opponent(s)`}
        </Typography>
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(2)}>
        <StatCard
          key={"avg_settlementPaymentsToParty"}
          title="Settlement payments to clients"
          value={currency.format(settlementPaymentsToParty.avg.getOr(0))}
          subTitle={`average per settlement`}
          valueVariant="h5"
          variant={
            settlementPaymentsToParty.avg.getOr(0) > 0 ? "positive" : "neutral"
          }
        />
        {settlementPaymentsByParty.avg.getOr(0) > 0 && (
          <StatCard
            key={"avg_settlementPaymentsToParty"}
            title="Settlement payments by clients"
            subTitle={`average per settlement`}
            value={currency.format(settlementPaymentsByParty.avg.getOr(0))}
            valueVariant="h5"
            variant={"negative"}
          />
        )}
        {settlementPaymentsToParty.max &&
          settlementPaymentsToParty.max.value > 0 && (
            <StatCard
              key={"max_settlementPaymentsToParty"}
              title="Highest settlement received"
              subTitle={`${allCases.get(settlementPaymentsToParty.max.caseId)?.results?.partyDescriptors[settlementPaymentsToParty.max.partyId].name} in '${allCases.get(settlementPaymentsToParty.max.caseId)?.name}'`}
              href={`/cases/${settlementPaymentsToParty.max.caseId}/analytics/settlement`}
              value={currency.format(settlementPaymentsToParty.max.value)}
              valueVariant="h5"
              variant="positive"
            />
          )}
        {settlementPaymentsByParty.max &&
          settlementPaymentsByParty.max.value > 0 && (
            <StatCard
              key={"max_settlementPaymentsByParty"}
              title="Highest settlement to pay"
              subTitle={`${allCases.get(settlementPaymentsByParty.max.caseId)?.results?.partyDescriptors[settlementPaymentsByParty.max.partyId].name} in '${allCases.get(settlementPaymentsByParty.max.caseId)?.name}'`}
              href={`/cases/${settlementPaymentsByParty.max.caseId}/analytics/settlement`}
              value={currency.format(settlementPaymentsByParty.max.value)}
              valueVariant="h5"
              variant="negative"
            />
          )}
      </Stack>
      <Divider />

      {/* quantums */}
      <Typography>Quantum claims of your clients</Typography>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(2)}>
        <StatCard
          key={"avg_quantumsClaimed"}
          title="Average claimed per case"
          value={currency.format(quantumsClaimed.avg.getOr(0))}
          subTitle={`across ${allCases.size} cases`}
          valueVariant="h5"
        />
        <StatCard
          key={"avg_quantumAmount"}
          title="Average quantum amount"
          value={currency.format(
            totalQuantumsClaimed === 0
              ? 0
              : quantumsClaimed.avg
                  .getOrBig(ZERO)
                  .div(totalQuantumsClaimed)
                  .toNumber()
          )}
          subTitle={`across ${totalQuantumsClaimed} quantums`}
          valueVariant="h5"
        />
        {quantumsClaimed.max && (
          <StatCard
            key={"max_quantumsClaimed"}
            href={`/cases/${quantumsClaimed.max.caseId}/configuration/claims`}
            title="Highest quantums claimed"
            value={currency.format(quantumsClaimed.max.value)}
            subTitle={`by ${allCases.get(quantumsClaimed.max.caseId)?.results?.partyDescriptors[quantumsClaimed.max.partyId].name} in '${allCases.get(quantumsClaimed.max.caseId)?.name}'`}
            variant="positive"
            valueVariant="h5"
          />
        )}
        {quantumsClaimed.min && (
          <StatCard
            key={"min_quantumsClaimed"}
            href={`/cases/${quantumsClaimed.min.caseId}/configuration/claims`}
            title="Lowest quantums claimed"
            value={currency.format(quantumsClaimed.min.value)}
            subTitle={`by ${allCases.get(quantumsClaimed.min.caseId)?.results?.partyDescriptors[quantumsClaimed.min.partyId].name} in '${allCases.get(quantumsClaimed.min.caseId)?.name}'`}
            variant="negative"
            valueVariant="h5"
          />
        )}
      </Stack>
      <Divider />
      <Typography>Quantum claims against your clients</Typography>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(2)}>
        <StatCard
          key={"avg_quantumsClaimed"}
          title="Average claimed per case"
          value={currency.format(quantumsClaimedAgainst.avg.getOr(0))}
          subTitle={`across ${allCases.size} cases`}
          valueVariant="h5"
        />
        <StatCard
          key={"avg_quantumAmount"}
          title="Average quantum amount"
          value={currency.format(
            totalQuantumsClaimedAgainst === 0
              ? 0
              : quantumsClaimedAgainst.avg
                  .getOrBig(ZERO)
                  .div(totalQuantumsClaimedAgainst)
                  .toNumber()
          )}
          subTitle={`across ${totalQuantumsClaimedAgainst} quantums`}
          valueVariant="h5"
        />
        {quantumsClaimedAgainst.max && (
          <StatCard
            key={"max_quantumsClaimedAgainst"}
            href={`/cases/${quantumsClaimedAgainst.max.caseId}/configuration/claims`}
            title="Highest quantums claimed"
            value={currency.format(quantumsClaimedAgainst.max.value)}
            subTitle={`against ${allCases.get(quantumsClaimedAgainst.max.caseId)?.results?.partyDescriptors[quantumsClaimedAgainst.max.partyId].name} in '${allCases.get(quantumsClaimedAgainst.max.caseId)?.name}'`}
            variant="negative"
            valueVariant="h5"
          />
        )}
        {quantumsClaimedAgainst.min && (
          <StatCard
            key={"min_quantumsClaimedAgainst"}
            href={`/cases/${quantumsClaimedAgainst.min.caseId}/configuration/claims`}
            title="Lowest quantums claimed"
            value={currency.format(quantumsClaimedAgainst.min.value)}
            subTitle={`against ${allCases.get(quantumsClaimedAgainst.min.caseId)?.results?.partyDescriptors[quantumsClaimedAgainst.min.partyId].name} in '${allCases.get(quantumsClaimedAgainst.min.caseId)?.name}'`}
            variant="positive"
            valueVariant="h5"
          />
        )}
      </Stack>
      <Copyright />
    </Container>
  );
};
